import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store";

const adminState = (state: RootState) => state.admin;
export const adminAlertState = (state: RootState) => state.admin.alerts;
const orderStatusChangeLogState = (state: RootState) => state.admin.ordersChangeLog;
const paymentDataStatusChangeLogState = (state: RootState) => state.admin.adminPaymentDataStatusChangeLog;
const cryptoExchangesState = (state: RootState) => state.admin.cryptoExchanges;
const orderState = (state: RootState) => state.admin.orders;

export const operatorsState = (state: RootState) => state.admin.operators;

export const providersSelector = createSelector(adminState, (state) =>
  [...state.providers].sort((a, b) => {
    {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }

      return 0;
    }
  })
);

export const adminAlertsSelector = createSelector(adminAlertState, (alerts) => alerts);
export const cryptoExchangesSelector = createSelector(cryptoExchangesState, (cryptoExchanges) =>
  [...cryptoExchanges].sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }

    return 0;
  })
);

export const operatorsSelector = createSelector(operatorsState, (operators) =>
  [...operators].sort((a, b) => {
    return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
  })
);

export const orderStatusChangeLogSelector = createSelector(orderStatusChangeLogState, (orderStatusChangeLog) =>
  [...orderStatusChangeLog].sort((a, b) => {
    return new Date(b.updatedUtc).getTime() - new Date(a.updatedUtc).getTime();
  })
);

export const paymentDataStatusChangeLogSelector = createSelector(
  paymentDataStatusChangeLogState,
  (paymentDataStatusChangeLog) =>
    [...paymentDataStatusChangeLog].sort((a, b) => {
      return new Date(b.updatedUtc).getTime() - new Date(a.updatedUtc).getTime();
    })
);

export const billingReportSelector = createSelector(orderState, (orders) =>
  [...orders].sort((a, b) => {
    return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
  })
);
