import { getToken } from "common/helpers/getToken";
import { store } from "store/store";
import { clearAuthData } from "modules/authModule/authSlice/authSlice";
import { refreshUser } from "modules/authModule/authSlice/authThunk";
import { clearStorageAndCookie, getStorageAuth } from "common/helpers/storageHandlers";

export interface IRequestBaseBody {
  method: string;
  body?: string | object | undefined;
}

export type RequestGenericType = string | object;

const VERSION = process.env.REACT_APP_API_VERSION;
const base = process.env.REACT_APP_API;
const catalog = process.env.REACT_APP_CATALOG;

export const request = async (
  url: string,
  data: IRequestBaseBody,
  bodyType?: string,
  baseToken?: string
): Promise<any> => {
  const token = baseToken ? baseToken : getToken();
  const headersForToken = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};

  const headerForMultiPart = bodyType
    ? {
        "Content-Type": bodyType,
      }
    : {};

  const response = await fetch(url, {
    ...data,
    // @ts-ignore
    headers: {
      ...headersForToken,
      ...headerForMultiPart,
    },
  });
  if (response.ok) {
    if (response.status === 204) {
      return true;
    }
    if (response.headers.get("Content-Length") === "0") {
      return true;
    }
    const typeResponse = response.headers.get("Content-Type");
    let result;
    if (typeResponse && /json/.test(typeResponse)) {
      result = response.json();

      return result;
    }
    result = await response.blob();

    return result;
  } else if (response.status === 401) {
    return await refreshApp(async () => request(url, data, bodyType));
  } else if (response.status > 399 && response.status < 500) {
    throw { isCustomError: true, status: response.status };
  } else {
    const bodyError = await response.json();
    throw { isCustomError: true, status: response.status, bodyError };
  }
};

export const get = async (url: string, bodyType?: string, baseToken?: string) =>
  request(`${base}${url}`, { method: "GET" }, bodyType, baseToken);

export const post = async <T extends RequestGenericType>(
  url: string,
  body: T | undefined,
  bodyType?: string,
  baseToken?: string
) => {
  return request(`${base}${url}`, { method: "POST", body }, bodyType, baseToken);
};

export const remove = async <T extends RequestGenericType>(url: string, body?: T | undefined, bodyType?: string) => {
  return request(`${base}${url}`, { method: "DELETE", body }, bodyType);
};

export const put = async <T extends RequestGenericType>(url: string, body: T | undefined, bodyType?: string) => {
  return request(`${base}${url}`, { method: "PUT", body }, bodyType);
};

export const refreshApp = async (method?: <T>(...args: any[]) => Promise<T>) => {
  let authData = store.getState().auth.authData;
  if (authData.refresh_token === "") {
    authData = getStorageAuth();
  }
  if (!navigator.onLine || authData.access_token === "") {
    return;
  }
  const metaDataRefresh = await store.dispatch(refreshUser(authData));
  if (metaDataRefresh.meta.requestStatus === "rejected") {
    logout();
  } else {
    if (method) {
      return await method();
    }
  }
};

export const logout = () => {
  clearStorageAndCookie();
  store.dispatch(clearAuthData());
  // TimerController.clearTimers();
  // logoutChannel?.postMessage("Logout");
};
