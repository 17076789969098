import { FC, lazy } from "react";
import { routesPaths } from "common/constants/routePaths";
import {
  createBrowserRouter,
  RouterProvider,
  RouterProviderProps,
  RouteObject,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ContentLayout } from "common/ui/contentLayout";
import { AppLayout } from "common/ui/appLayout";
import { AuthLayout } from "common/ui/authLayout";
import { AuthFormsLayout } from "modules/authModule/components/authFormsLayout";
import { SighInPage } from "./authPages/signInPage";
import { Roles } from "common/enum/role";
import { useAuthAndPolicy } from "common/hooks/useAuthAndPolicy";
import { NotFoundPage } from "./notFoundPage";
import { ErrorBoundary } from "react-error-boundary";
import { ErroBoundaryContent } from "common/components/erroBoundaryContent";
import { HealthPage } from "./HealthPage";
import React from "react";

const OrdersTablePage = lazy(async () =>
  import("./managerPages/ordersTablePage").then((module) => ({
    default: module.OrdersTablePage,
  }))
);

const CryptoExchangePage = lazy(async () =>
  import("./adminPages/cryptoExchangePage").then((module) => ({
    default: module.AddCertificatePage,
  }))
);

const PaymentProvidersPage = lazy(async () =>
  import("./adminPages/paymentProvidersPage").then((module) => ({
    default: module.PaymentProvidersPage,
  }))
);

const OrderstatusChangeLogPage = lazy(async () =>
  import("./adminPages/orderStatusChangeLogPage").then((module) => ({
    default: module.OrderStatusChangeLogPage,
  }))
);

const PaymentDataStatusChangeLogPage = lazy(async () =>
  import("./adminPages/paymentProviderChangeLogPage").then((module) => ({
    default: module.PaymentProviderChangeLogPage,
  }))
);

const OperatorPage = lazy(async () =>
  import("./adminPages/operatorsPage").then((module) => ({
    default: module.OperatorsPage,
  }))
);

const BillingReportPage = lazy(async () =>
  import("./adminPages/billingReportPage").then((module) => ({
    default: module.BillingReportPage,
  }))
);

const SettingPasswordPage = lazy(async () =>
  import("./authPages/settingPasswordPage").then((module) => ({ default: module.SettingPasswordPage }))
);

const ForgotPassworPage = lazy(async () =>
  import("./authPages/forgotPasswordPage").then((module) => ({ default: module.ForgotPasswordPage }))
);

const authRoutes: RouteObject[] = [
  {
    path: routesPaths.auth.base || routesPaths.auth.signIn,
    element: (
      <ErrorBoundary FallbackComponent={ErroBoundaryContent}>
        <AuthLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: routesPaths.admin.base,
        element: <SighInPage />,
      },
      {
        path: routesPaths.auth.forgot,
        element: <ForgotPassworPage />,
      },
      {
        path: routesPaths.auth.invitation,
        element: <SettingPasswordPage mode="accept" />,
      },
      {
        path: routesPaths.auth.recoveryPassword,
        element: <SettingPasswordPage mode="recovery" />,
      },
      {
        path: routesPaths.auth.doubleAuth,
        element: (
          <ErrorBoundary FallbackComponent={ErroBoundaryContent}>
            <AuthFormsLayout />
          </ErrorBoundary>
        ),
        children: [
          {
            path: routesPaths.auth.doubleAuth || routesPaths.auth.doubleSignIn,
            element: <SighInPage />,
          },
          {
            path: routesPaths.auth.doubleForgot,
            element: <ForgotPassworPage />,
          },
          {
            path: routesPaths.auth.doubleInvitation,
            element: <SettingPasswordPage mode="accept" />,
          },
          {
            path: routesPaths.auth.doubleRecoveryPassword,
            element: <SettingPasswordPage mode="recovery" />,
          },
        ],
      },
    ],
  },
];

const managerRoute: RouteObject[] = [
  {
    path: routesPaths.menager.base,
    element: (
      <ErrorBoundary FallbackComponent={ErroBoundaryContent}>
        <ContentLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: routesPaths.menager.base,
        element: (
          <ErrorBoundary FallbackComponent={ErroBoundaryContent}>
            <OrdersTablePage />
          </ErrorBoundary>
        ),
      },
    ],
  },
];

const adminRoutes: RouteObject[] = [
  {
    path: routesPaths.admin.base,
    element: (
      <ErrorBoundary FallbackComponent={ErroBoundaryContent}>
        <ContentLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: routesPaths.admin.base,
        element: <OperatorPage />,
      },
      {
        path: routesPaths.admin.cryptoExchange,
        element: <CryptoExchangePage />,
      },
      {
        path: routesPaths.admin.paymentProvider,
        element: <PaymentProvidersPage />,
      },
      {
        path: routesPaths.admin.orderChangeLog,
        element: <OrderstatusChangeLogPage />,
      },
      {
        path: routesPaths.admin.paymentChangeLog,
        element: <PaymentDataStatusChangeLogPage />,
      },
      {
        path: routesPaths.admin.billingReport,
        element: <BillingReportPage />,
      },
    ],
  },
];

const heathRoute = {
  path: routesPaths.health,
  element: <HealthPage />,
};

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.captureConsoleIntegration(),
  ],

  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const getActiveRoutes = (role?: Roles): RouterProviderProps["router"] => {
  const routesByRole: RouteObject[] =
    role === Roles.ADMIN ? adminRoutes : role === Roles.MENAGER ? managerRoute : authRoutes;

  const router: RouteObject[] = [
    {
      path: routesPaths.menager.base,
      element: (
        <ErrorBoundary fallback={<ErroBoundaryContent />}>
          <AppLayout />
        </ErrorBoundary>
      ),
      children: [...routesByRole, heathRoute],
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ];

  return sentryCreateBrowserRouter(router);
};

const Pages: FC = () => {
  const { activeRole } = useAuthAndPolicy();

  return <RouterProvider router={getActiveRoutes(activeRole)} />;
};

export default Pages;
